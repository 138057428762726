#home {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: 100%;
  width: 60%;
  padding-top: 120px;
  padding-bottom: 10%;
}

@media (max-width: 450px) {
  #home {
    width: 50%;
  }

  .row1 {
    height: calc(50vh- 50px);
  }
}

#simon-logo2 {
  margin: 0 auto;
  display: block;
  height: 250px;
  width: 20%;
  padding-top: 170px;
}

#locations-row {
  margin: 0;
}

.row1 {
  background-color: #152238;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-blend-mode: darken;
  width: 100%;
  justify-content: center;
  text-align: center;
  display: block;
}

.row2 {
  background-image: url("../scissors-background.PNG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  height: 525px;
  width: 100%;
  vertical-align: center;
  justify-content: center;
}

.bg-text {
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  padding-top: 200px;
  text-shadow: -1px black, 1px black, -1px black, 1px black;
}

.bg-subtext {
  color: grey;
  font-weight: bold;
  font-size: 20px;
  padding: 30px;
  text-align: center;
  font-family: "PT Sans", sans-serif;
}

.gold-seperator {
  border-top: 5px solid #a5770a;
  font-family: "PT Sans", sans-serif;
  width: 10%;
  margin: auto;
}

.services-section {
  text-align: center;
  background-color: #e5e5e5;
  padding: 30px;
}

.section-title {
  font-family: "PT Sans", sans-serif;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
}

#simon-headshot {
  padding-top: 25px;
  width: 25%;
  border-radius: 50%;
}

#jase-headshot {
  padding-top: 25px;
  height: 350px;
  border-radius: 50%;
}

@media (max-width: 500px) {
  #simon-headshot {
    width: 80%;
  }
}

@media (min-width: 500px) {
  #simon-headshot {
    max-height: 350px;
    width: auto;
  }
}

@media (min-width: 500px) {
  #shina-headshot {
    max-height: 300px;
    width: 300px;
    border-radius: 50%;
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  #shina-headshot {
    width: 80%;
    border-radius: 50%;
  }
}

.about-header {
  padding-bottom: 10px;
  font-family: "Playfair Display, serif" !important;
  padding-top: 15px;
  margin-top: 5%;
}

.simon-headshot-row {
  text-align: center;
  justify-content: center;
  width: 100%;
}

.contact-section {
  padding-top: 20px;
  text-align: center;
  background-color: white;
}

#about-text {
  padding-right: 15px;
  padding-left: 15px;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  color: grey;
}

#simon-bio-text {
  margin-left: -15px;
  margin-right: -15px;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  color: grey;
}

#phone-icon {
  height: 25px;
  width: auto;
  color: white;
}

.contact-row {
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}

#call-me-link {
  color: #a5770a;
}

#call-me-link:hover {
  color: #a5770a;
  text-decoration-color: none;
}

.form-style {
  margin: 5px;
  font-family: "Playfair Display, serif";
}

.contact-form {
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
}

.form-control::placeholder {
  font-family: "Playfair Display, serif";
}

.form-group {
  border: 1px solid #ccc;
  border-style: inset;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.contact-text {
  font-family: "Playfair Display, serif";
  font-size: 22px;
  color: black;
}

@media (max-width: 500px) {
  .contact-text {
    font-family: "Playfair Display, serif";
    font-size: 18px;
    color: black;
  }
}

.fa-instagram {
  color: #a5770a;
  height: 10px;
  width: auto;
}

.fa-facebook {
  color: #a5770a;
  padding-left: 10px;
}

.emailSubmitButton {
  background-color: #a5770a;
  border-color: #a5770a;
  color: white;
  width: 100%;
  font-family: "Playfair Display, serif";
  margin-bottom: 30px;
}

.emailSubmitButton:hover {
  background-color: #a5770a;
  border-color: #a5770a;
  color: white;
  width: 100%;
  font-family: "Playfair Display, serif";
}

.testimonial-row {
  background-color: #e5e5e5;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 70px;
  height: 100%;
}

.carousel-styling {
  padding: 30px;
  font-family: "Playfair Display, serif";
  color: black;
}

.bookButton {
  background-color: #a5770a;
  border-color: #a5770a;
  color: white;
  font-family: "Playfair Display, serif";
  margin-bottom: 30px;
}

.bookButton:hover {
  background-color: #a5770a;
  border-color: #a5770a;
  color: white;
  font-family: "Playfair Display, serif";
}

#services-table {
  margin-top: 20px;
  margin: 30px;
  width: 100%;
}

.service-name {
  background: none;
}

#scissors-image {
  width: 250px;
  height: auto;
  padding-top: 55px;
}

.services-button {
  background-color: #152238;
  border-color: #152238;
  font-size: 25px;
  font-family: "Playfair Display, serif";
  margin-right: 5px;
  margin-top: 5px;
}

.services-button:hover {
  background-color: #152238;
  border-color: #152238;
  font-size: 25px;
  font-family: "Playfair Display, serif";
  margin-right: 5px;
  margin-top: 5px;
}

#button-columns {
  margin-top: 30px;
}

.price {
  font-weight: bold;
}

.testimonial-title {
  font-family: "Lobster", cursive;
}

#serviceInfo-row {
  width: 100%;
  padding-left: 15%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.covid-info {
  width: 100%;
  padding-left: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}

#covid-headers {
  font-family: "Playfair Display, serif";
  margin-top: 5%;
  padding-left: 10px;
  width: 100%;
}

#covid-desc {
  padding: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  width: 80%;
  word-wrap: break-word;
  color: grey;
}

.covid-measures {
  padding-left: 5%;
}

.booking-procedures {
  padding-left: 5%;
}

.hygiene-row {
  padding-left: 5%;
}

.about-simon {
  width: 100%;
  padding-left: 15%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 10%;
  margin-left: 0%;
  margin-right: 0%;
}

#appointment-button {
  margin: 0;
}

#moustache-row {
  margin: 0;
}

.service-desc {
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  width: 80%;
  word-wrap: break-word;
  color: grey;
}

.serviceInfo-title {
  font-family: "Playfair Display, serif";
  width: 80%;
  margin-top: 18px;
}

#moustache-icon {
  width: 150px;
  height: auto;
}

#ozzie-rizzo-pic {
  width: 70%;
  height: auto;
  border-radius: 5px;
}

#nearcut {
  margin-top: 17%;
  width: 100%;
}

#nearcut-row {
  margin-top: 15%;
}

.btn-container {
  margin-top: 25%;
}

.book-now-button2 {
  margin: 10px;
  position: fixed;
  bottom: 1%;
  right: 1%;
  z-index: 9999;
  padding: 20px;
  border-radius: 20px;
  background-color: #a5770a;
  border-color: #a5770a;
  font-family: "Playfair Display, serif";
  font-size: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.book-now-button2:hover {
  margin: 10px;
  position: fixed;
  bottom: 1%;
  right: 1%;
  z-index: 9999;
  padding: 20px;
  border-radius: 20px;
  background-color: #a5770a;
  border-color: #a5770a;
  font-family: "Playfair Display, serif";
  font-size: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.service-time {
  font-size: 25px;
}

.carousel-item {
  height: 100%;
}
