.navBar-layout {
  background-color: #152238;
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  z-index: 100000;
}

@media (max-width: 600px) {
  .navBar-layout {
    justify-content: start;
  }
}

#barber-logo {
  height: 50px;
  width: auto;
}

.link {
  color: white;
  font-family: "Playfair Display", serif;
}

.link:hover {
  color: white;
  border-bottom: 2px solid #a5770a;
  font-family: "Playfair Display", serif;
}

#basic-navbar-nav {
  justify-content: center;
}

.navbar .navbar-toggler-icon {
  background-image: url("https://mdbootstrap.com/img/svg/hamburger7.svg?color=ffffff");
}
