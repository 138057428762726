.footer {
  height: 100px;
  background-color: #152238;
}

.footer-text {
  color: whitesmoke;
  font-size: 20px;
  font-family: "Playfair Display, serif";
}

#simons-footer-logo {
  height: 50px;
  width: auto;
}

#footer-logo {
  height: 100%;
}
